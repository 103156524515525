@import "~bootstrap/scss/bootstrap";
body {
    background-color: #dee6ee;
}

:root {
    --amplify-primary-color: #18465f;
}

[data-amplify-authenticator] {
    --amplify-components-button-primary-background-color: var(--amplify-colors-neutral-90);
}

[data-amplify-authenticator][data-variation=modal] {
    padding-top: 1vh;
  }