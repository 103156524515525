.site-footer {
    background-color:rgb(24, 70, 95);
    color: white;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 11px;
    ::before {
        background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMDAnIGhlaWdodD0nMTAwJyB2aWV3Qm94PScwIDAgMTAwIDEwMCcgcHJlc2VydmVBc3BlY3RSYXRpbz0nbm9uZSc+PHBvbHlnb24gcG9pbnRzPScxMDAsMCAxMDAsMTAwIDAsMTAwJyBzdHlsZT0nZmlsbDojMTg0NjVmOycgLz48L3N2Zz4=);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        content: ' ';
        display: block;
        position: absolute;
        width: 100%;
        left: 0;
        top: -40px;
        height: 40px;
    }
    a {
        &:hover {
            color: #fff !important;
        }
        color: #00adee;
        transition: .3s;
        text-decoration: none;
    }
}