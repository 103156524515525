#x5as2-container {
    height: calc(100vh - 88px);
    iframe {
        width: 100%;
        height: 100%;
    }
}


ul.asStreamList {
    margin-bottom: 160px;
    list-style: none;
   li {
       div.asStreamButton {
           background-color: #18465f;
           color: white;
           max-width: 290px;
           min-width: 285px;
           min-height: 45px;
           display: block;
           align-items: center;
           text-align: center;
           word-wrap: break-word;
           padding: .5em;
           margin-bottom: 12px;
           line-height: 22px;
           text-align: center;
           &:hover {
               cursor: pointer;
               background-color: #00adee;
           }
           &.launching{
               cursor: pointer;
               background-color: #00adee;
           }
       }
   }
}
